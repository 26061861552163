.no-bullets{
    list-style-type: none;
}

.card-columns {
    @include media-breakpoint-only(lg) {
      column-count: 4;
    }
    @include media-breakpoint-only(xl) {
      column-count: 5;
    };
    display: inline-block;
  }

