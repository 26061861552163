@import './scss/custom.css';

.project-card-image {
  min-width:150px;
  min-height:150px;
  max-width: 150px;
  max-height: 150px;

  /* width: auto; */
  /* height: auto; */
  object-fit: cover;
}

@media (min-width: 1000px) {
  .project-card-image {
    min-width:150px;
  min-height:150px;
    max-width: 150px;
    max-height: 150px;
    object-fit: cover;

  }

}


.project-card {
    /* background-color: #e5d9b6 ; */
    /* border-width: 10px; */
    /* border-color: #be9e44; */
    background-color: #e5d9b6;
    /* border-radius: 15px; */
    border: none;
    min-height: 180px;
    min-width: 180px;
    max-height: 180px;
    max-width: 180px;
    /* align-items: center; */
    /* margin: 15px 15px 15px 15px; */
    

}


.album-icon {
  filter: invert(75%) sepia(71%) saturate(267%) hue-rotate(2deg) brightness(86%) contrast(84%);

}

.card-img-overlay {
  max-width: 150px;
  max-height: 150px;
  min-width: 150px;
  min-height: 150px;
  
}


.card-img-overlay-about {
  line-height: 10px!important;
  
}
p {
  line-height: 1;
}