@import-normalize;
@import './scss/custom.css';
@import './fonts.css';
@import './services.css';
@import './backgroundImage.css';
@import './navbar.css';
@import './contactForm.css';
@import './projects.css';
@import './home.css';
@import './csstemplates/main.css';
@import './equipment.css';





*, *::before, *::after {
  font-family: "Source Serif", serif;
  box-sizing: border-box;
}

:root {
  --primary: #cbb26A;
  --secondary: #1a1a1a;
  --success: #d8c690;
  --danger: #D25425;
  --info: #e5d9b6;
}

.fullscreen {
    min-height: 120vh;
};


/* @media (max-width: 900px) {
.wid-50 {
  width: 100%;
  
}
} */

.services-card {
  min-height: 600px;
};

.services-card:hover {
  background-color: yellow;
}


/* Services */
body{
  margin-top:20px;
  background:#F0F8FF;
  }
.text-custom{
  color: #0062ff !important;
  }

.blankspace {
  min-height: 200px;
}

.max-height-100 {
  max-height: 100px;
}




body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hover-grow {
  transition: transform .2s;
  margin: 0 auto;
}

.hover-grow:hover {
  transform: scale(1.1);
}



.fullscreen {
  min-height: 120vh;
};

.fade-in {
	animation: fadeInAnimation ease 2s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
	0% {
		opacity: 0;
    transform: translateY(10%);
	}
	100% {
		opacity: 1;
	}
}



.deskPicture {
  background-position: center;
}

.h-10 {
  height: 10%;
}

@media (max-width: 786px) {
  .hover-appear {
    opacity:1;
    background-color: rgba(0,0,0,0.5);
  }
}

@media only screen and (min-width: 768px) {
  .hover-appear {
    opacity:0;
    transition: .5s;
  }
  
  .hover-appear:hover {
    opacity:1;
    background-color: rgba(0,0,0,0.5);
  }

  .img-response {
    visibility: visible;
  }
}


/* .img-response {
  visibility: hidden;
} */

.darken-img {
  object-fit: cover;
  background-color: black;
  opacity: 50%;
}




/* Flip Cards */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

/* Reseting */




.container{
    transform-style: preserve-3d;
}

.container .box{
    position: relative;
    width: 800px;
    height: 400px;
    margin: 20px;
    transform-style: preserve-3d;
    perspective: 1000px;
    cursor: pointer;
    
}

.container .box .body{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: 0.9s ease;
}



.container .box .body .imgContainer{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
}

.container .box .body .imgContainer img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.container .box .body .content{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #333;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transform: rotateX(180deg);
}

.container .box:hover .body{
    transform: rotateX(180deg);
}

.container .box .body .content div{
    transform-style: preserve-3d;
    padding: 20px;
    /* background: #2E83B7; */
    background: linear-gradient(180deg, #2E83B7,#8ec5e2);
    transform: translateZ(100px);
}

.container .box .body .content div h3{
    letter-spacing: 1px;
}

.icon-service {
  margin: 0rem 4rem 2rem 0rem;
}

.bg-dark-primary {
  background-color: #45677d;
}



/* @media only screen and (min-width: 768px) {} */

.font-about {
  font-size: calc(10px + .500625vw);
  /* font-size: 1.5rem; */

}

