.has-bg-img {
    /* background-image: url("../images/soundboard1.jpg"); */
    background-image: url("../images/ClientGallery/equipment.jpg");

    height: 120%;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    width: 100%;
    z-index: -1;
  }

  .about-img {
      width: auto;
      max-width: 800px;
      height: auto;
      max-height: 800px;
      position: relative;
  overflow: hidden;
  }

  .deskPicture {
    background-image: url('../images/ClientGallery/equipment.jpg');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

  }

  
  .delivery-cover-image {
    background-image: url("../images/ClientGallery/BWEquipment.JPG");

    /* height: 40%; */
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    /* position: fixed; */
    width: 100%;
    z-index: -1;
    

  }