
.w-50-100 {
    width: 50%;
}

@media (max-width: 1200px) {
    .w-50-100 {
        width: 100%;
    }
}

@media (max-width: 1000px) {
    .hide-l-1000 {
        display: none;
    }
}

@media (min-width: 1000px) {
    .hide-g-1000 {
        display: none;
    }
}
@media (min-width: 1400px) {
    .hide-g-1400 {
        display: none;
    }
}

@media (max-width: 1400px) {
    .hide-l-1400 {
        display: none;
    }
}

@media (max-width: 1200px) {
    .hide-l-1200 {
        display: none;
    }
}